import "./src/scss/style.scss";

const addScript = (url) => {
  const script = document.createElement("script");
  script.src = url;
  script.async = true;
  document.body.appendChild(script);
};

export const onClientEntry = () => {
  window.onload = () => {
    addScript("https://widget.clutch.co/static/js/widget.js");
  };
};
